import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import A_Divider from "../../../../Atoms/DataDisplay/Dividers/A_Divider";
import A_IconButton from "../../../../Atoms/Inputs/Buttons/A_IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { LayoutMenusStructure } from "./LayoutMenu";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import ClientLogoComponent from "./clientLogo";
import { useDispatch } from "react-redux";
import  {getCurrentEventValue}  from "../../../../../../store/actions/getEventValueAction";
import A_SimpleDialog from "../../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import A_Button from "../../../../Atoms/Inputs/Buttons/A_Button";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "250px",
    maxWidth: 360,
    marginTop: "1px",
    height: "100%",
  },
  layoutGroup: {
    fontSize: 15,
    color: "white",
    fontWeight: "bold",
    fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif !important",
  },
  layoutlist: {
    transition: "none !important",
  },
  padding: {
    padding: "10px",
    cursor: "default",
    fontWeight: "bold",
    pointerEvents: "none",
    fontSize: "2px",
  },

  clickableBtns: {
    padding: "0px 16px !important",
    fontSize: "15px",
    "&:hover": { background: "#3c4952", color: "#e0eff6" },
  },
  sidebarDividerLine: {
    marginTop: "16px",
    height: "1px",
    background: "white",
    opacity: "0.2",
  },

  active: {
    background: "#337ab7",
    color: "#e0eff6",
    "&:hover": { background: "#337ab7" },
  },
  pagesidebarClosebutton: {
    padding: "0px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "27%",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "7%",
    },
  },
  pagesidebarClosebuttonone: {
    visibility: "hidden",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 3),

    /*  backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    }, */
    borderRadius: "6px",
  },
}));

const M_LayoutMenu = (props) => {
  const classes = useStyles();
  const [layouts, setlayouts] = useState(LayoutMenusStructure(props.layouts));

  const [layoutsmenus, setLayoutsmenus] = useState([]);
  let { crmId } = useParams();
  const [currentuser, setCurrentuser] = React.useState("");
  const [menuItem,setMenuItem] = useState("");
  const [isMsgOpen, setIsMsgOpen] = useState(false);
  const eventValue = useSelector((state)=>state.getEventValueStore);
  const dispatch = useDispatch();  
  const _userid = useSelector((state) =>
    state &&
    state.getLoggedInUserReducer &&
    state.getLoggedInUserReducer.data &&
    state.getLoggedInUserReducer.data[0] &&
    state.getLoggedInUserReducer.data[0].id
      ? state.getLoggedInUserReducer.data[0].id
      : ""
  );

  let loggedInUserDetail = JSON.parse(localStorage.getItem("contact_crm"));
  const userid =
    loggedInUserDetail && loggedInUserDetail.id
      ? loggedInUserDetail.id
      : _userid;

  const searchtext = "";
  useEffect(() => {
    setCurrentuser(userid);
  }, [userid]);
  useEffect(() => {
    setlayouts(LayoutMenusStructure(props.layouts));
  }, [props.layouts]);
  let clientName =
    window.location &&
    window.location.hostname &&
    window.location.hostname.split(".") &&
    window.location.hostname.split(".").length > 0
      ? window.location.hostname.split(".")[0]
      : "";
  
  React.useEffect(() => {
    const results = layouts.filter((layout) => {
      if (
        layout &&
        layout.name &&
        layout.name.toLowerCase().includes(searchtext)
      ) {
        return true;
      } else if (layout && layout.children && layout.children.length > 0) {
        let res = layout.children.filter((child) =>
          child.name.toLowerCase().includes(searchtext)
        );
        if (res && res.length > 0) {
          return true;
        }
      }
    });
    setLayoutsmenus(results);
    return function cleanup() {
      setLayoutsmenus([]);
    };
  }, [searchtext, layouts]);
  function check(data) {
    try {
      var res = "";
      if (typeof data == "object") {
        res = data.isPrivate.toLowerCase();
      } else {
        var newdata = JSON.parse(data);
        res = newdata.isPrivate.toLowerCase();
      }
      return res;
    } catch (e) {
      return false;
    }
  }
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      console.log("hi");
      if (eventValue) {
        console.log("hi2");
        const confirmationMessage = "You have unsaved changes. Do you really want to leave?";
        event.preventDefault();
        event.returnValue = confirmationMessage; // For modern browsers
        return confirmationMessage;    }
    };
    const handleUnload = () => {
      if (eventValue) {
        setTextChanged(false);
        dispatch(getCurrentEventValue(false));
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, [eventValue]);
  let handleCancelPopup=()=>{
    setIsMsgOpen(false);

  }
  let handleLeavePopup=()=>{
    setIsMsgOpen(false);
    props.callback(menuItem);
    dispatch(getCurrentEventValue(false));
  }

  let handleMsgPopup = () => {
    setIsMsgOpen(false);
  };
  const getPublishActionItems = () => {
    return (
      <>
        <A_Button
          color="primary"
          label="Leave"
          autoFocus
          onClick={handleLeavePopup}
        />
        <A_Button
          color="default"
          label="Cancel"
          onClick={handleCancelPopup}
        />
      </>
    );
  };
  const handleClick = (e,item) => {
    if(eventValue){
      setMenuItem(item);
      setIsMsgOpen(true) 
       return;
    }
      props.callback(item);
  };

  const inMenuHiddenTemplates = (templates) => {
    let toShowTemplates = templates.filter(
      (item) => {
        let parsedTemplateJson = item?.templateJson;
        if(typeof parsedTemplateJson == "string"){
          parsedTemplateJson = JSON.parse(parsedTemplateJson);
        }
        return !parsedTemplateJson?.show?.inMenuHidden;
      }
    );
    return toShowTemplates;
  };

  return (
    <>
    <List component="nav" aria-label="layout side menu">
      <ListItem
        button
        className={classes.pagesidebarClosebuttonone}
        id="sidebarclosebutton"
      >
        <A_IconButton
          size="small"
          icon={<CloseIcon />}
          onClick={() => props.callback()}
        ></A_IconButton>
      </ListItem>

      <ListItem className={classes.pagesidebarClosebutton}>
        <ClientLogoComponent clientName={clientName} />
      </ListItem>
      {/* Search layout is disabled - 21-11-2021 */}
      {/* <ListItem button>
        <InputBase
          placeholder="Search layout"
          onChange={handleLayoutMenu}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ "aria-label": "search" }}
        />
      </ListItem> */}

      {layoutsmenus && layoutsmenus.length > 0
        ? layoutsmenus
            .sort(function (a, b) {
              return a.sortOrder - b.sortOrder;
            })
            .map((item) => {
              return (
                <div key={`fragment_${item.name}`}>
                  <div
                    className={classes.sidebarDividerLine}
                    key={`div_${item.id}`}
                  ></div>
                  <ListItem
                    key={item.id}
                    button
                    className={(classes.padding, classes.layoutlist)}
                  >
                    <ListItemText
                      primary={
                        <Typography className={classes.layoutGroup}>
                          {item.name}
                        </Typography>
                      }
                    />
                  </ListItem>

                  {item && item.children && item.children.length > 0
                    ?   inMenuHiddenTemplates(item.children)
                        .sort(function (a, b) {
                          return a.itemSortIndex - b.itemSortIndex;
                        })
                        .map((item) => {
                          return (
                            <ListItem
                              key={item.id}
                              onClick={(e) => handleClick(e, item)}

                              className={`${
                                (classes.layoutlist, classes.clickableBtns)
                              } ${
                                item.id === props.currentPageId
                                  ? classes.active
                                  : ""
                              }`}
                              button
                            >
                              {check(item.templateJson) == "true" ? (
                                crmId == currentuser ? (
                                  <ListItemText primary={item.name} />
                                ) : (
                                  ""
                                )
                              ) : (
                                <ListItemText primary={item.name} />
                              )}
                            </ListItem>
                          );
                        })
                    : ""}
                </div>
              );
            })
        : ""}

      <A_Divider />
    </List>
    <A_SimpleDialog
        open={isMsgOpen}
        dialogContent=""
        closePopUp={handleMsgPopup}
        title="You have unsaved changes in the template. You can leave the page to discard the changes or cancel to continue editing and save all updates."
        dialogActions={getPublishActionItems()}
        onClose={handleMsgPopup}
      />
    </>
  );
};
M_LayoutMenu.propTypes = {
  callback: PropTypes.func,
  layouts: PropTypes.array,
  getLoggedInUser: PropTypes.func,
  loggedinUser: PropTypes.func,
  loggedIn: PropTypes.array,
  currentPageId: PropTypes.string,
};
export default M_LayoutMenu;
