import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Draggable from "react-draggable";
import Paper from "@material-ui/core/Paper";
import CloseIcon from "@material-ui/icons/Close";
import { Zoom } from "@material-ui/core";
import theme from "../../../../Themes/defaultTheme";
import A_IconButton from "../../Inputs/Buttons/A_IconButton";
import "./A_SimpleDialog.css";
import PropTypes from "prop-types";
import { useRef } from "react";

const PaperComponent = (props) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="A_DialogContent-root"]'}
      bounds="parent"
    >
      <Paper {...props} style={{ margin: 0 }} />
    </Draggable>
  );
};

const A_SimpleDialog = (props) => {
  const divRef = useRef(null);
  const useStyles = makeStyles((theme) => ({
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    ircResize: {
      //  overflowY:"hidden",
      "& section": {
        height: "215px",
      },
    },
    titleWithPadding: {
      paddingRight: theme.spacing(4),
    },
  }));
  const classes = useStyles();
  const handleOnClose = () => {
    if (divRef.current) {
      divRef.current.scrollTop = 0;
    }
    if (props.onClose) {
      props.onClose();
    }
  };
  const stopPropagationForTab = (event) => {
    if (event.key === "Tab") {
      event.stopPropagation();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        onKeyDown={stopPropagationForTab}
        showclosebutton={props.showclosebutton}
        onClose={handleOnClose}
        onExit={props.onExit}
        aria-labelledby="simple-dialog-title"
        open={props.open}
        TransitionComponent={Zoom}
        fullScreen={props.fullScreen}
        fullWidth={props.fullWidth}
        maxWidth={props.maxWidth}
        PaperComponent={PaperComponent}
        disableBackdropClick={props.disableBackdropClick}
        disableEscapeKeyDown={props.disableEscapeKeyDown}
      >
        <DialogTitle  className={
         props.condition != true  && props.showclosebutton == "true" 
          ? classes.titleWithPadding
          : ""
      }>
          <span
            style={{ cursor: props.title == "" ? "" : "move" }}
            id={
              props.title == ""
                ? "simple-dialog-title"
                : "draggable-dialog-title"
            }
          >
            {props.title}
          </span>
          {props.showclosebutton == "true"  && props.condition != true ? (
            <A_IconButton
              icon={<CloseIcon />}
              aria-label="close"
              className={classes.closeButton}
              onClick={handleOnClose}
            ></A_IconButton>
          ) : null}
          <div>
            <hr style={{ width: "95%" }} />
          </div>
        </DialogTitle>
        <DialogContent
          id="DialogContent"
          ref={divRef}
          className={props.formResize ? classes.ircResize : null}
        >
          {props.IRCdate && (
            <DialogContentText>{props.IRCdate}</DialogContentText>
          )}
          <DialogContentText id="alert-dialog-description">
            {props.dialogContent}
          </DialogContentText>
        </DialogContent>

        <DialogActions>{props.dialogActions}</DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};
A_SimpleDialog.defaultProps = {
  open: false,
  title: "",
  height: "",
  dialogContent: "Dialog content Placeholder",
  fullScreen: false,
  fullWidth: true,
  maxWidth: "xs",
  showclosebutton: "true",
  disableBackdropClick: true,
  disableEscapeKeyDown: false,
  //transition: <Slide />,
};
A_SimpleDialog.propTypes = {
  onClose: PropTypes.func,
  onExit: PropTypes.any,
  open: PropTypes.bool,
  maxWidth: PropTypes.string,
  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  title: PropTypes.any,
  showclosebutton: PropTypes.any,
  IRCdate: PropTypes.any,
  dialogContent: PropTypes.any,
  dialogActions: PropTypes.any,
  fullScreen: PropTypes.bool,
  fullWidth: PropTypes.bool,
  formResize: PropTypes.bool,
  condition: PropTypes.any,
};
export default A_SimpleDialog;
